// Libraries
import React from 'react';
import {BrowserRouter} from 'react-router-dom';

// Supermove
import {ApolloTypedClientType, ClientType} from '@supermove/graphql';

// App
import App from './App';
import AppTyped from './AppTyped';

interface BrowserProps {
  client: ClientType;
  clientTyped: ApolloTypedClientType;
  children: React.ReactNode;
}

const BrowserTyped = ({client, clientTyped, children}: BrowserProps) => (
  <App client={client}>
    <AppTyped client={clientTyped}>
      <BrowserRouter>{children}</BrowserRouter>
    </AppTyped>
  </App>
);

export default BrowserTyped;
