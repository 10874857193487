// Libraries
import React from 'react';

// Supermove
import {BrowserTyped} from '@supermove/app';

// App
import {client, clientTyped} from 'config';

export const wrapRootElement = ({element}) => (
  <BrowserTyped client={client} clientTyped={clientTyped}>
    {element}
  </BrowserTyped>
);
