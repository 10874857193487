/* eslint-disable */
import * as types from './graphql';
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
  '\n  query GlobalDashboardDetailsPage(\n        $globalDashboardUuid: String!\n    ) {\n    viewer {\n      id\n      viewingOrganization {\n        id\n        exploUserGroupToken\n      }\n    }\n    globalDashboard(\n      uuid: $globalDashboardUuid, \n    ) {\n        id\n        uuid\n        name\n        exploDashboardEmbedId\n        exploVariables\n        status\n        ...GlobalDashboardDetailsPageSettingsPanel\n        ...GlobalDashboardDetailsPageAboutPanel\n    }\n  }\n':
    types.GlobalDashboardDetailsPageDocument,
  '\n  query ExploDashboardPanel($slug: String) {\n    viewer {\n      id\n      viewingOrganization {\n        id\n        slug\n      }\n    }\n    organization(slug: $slug) {\n      id\n      ...OrganizationItem\n    }\n  }\n':
    types.ExploDashboardPanelDocument,
  '\n  fragment GlobalDashboardDetailsPageAboutPanel on GlobalDashboard {\n    id\n    uuid\n    companiesCount\n    publishedAt\n    publishedBy {\n      id\n      fullName\n    }\n    updatedAt\n    updatedBy {\n      id\n      fullName\n    }\n  }\n':
    types.GlobalDashboardDetailsPageAboutPanelFragmentDoc,
  '\n  fragment GlobalDashboardDetailsPageSettingsPanel on GlobalDashboard {\n    id\n    name\n    status\n    description\n    isDefault\n    helpArticleUrl\n    isDefault\n    exploVariables\n    exploDashboardEmbedId\n    dashboardCategory {\n      id\n      name\n    }\n    dashboardTags {\n      tag {\n        id\n        name\n      }\n    }\n  }\n':
    types.GlobalDashboardDetailsPageSettingsPanelFragmentDoc,
  '\n  \n  query ManageGlobalDashboardCompaniesDrawer($globalDashboardUuid: String!) {\n    \n    globalDashboard(uuid: $globalDashboardUuid) {\n        id\n        dashboards {\n          id\n          uuid\n          organizationId\n        }\n    }\n    activeOrganizations {\n      id\n      ...ManageGlobalDashboardCompaniesDrawerListFragment\n    }\n  }\n':
    types.ManageGlobalDashboardCompaniesDrawerDocument,
  '\n  mutation ManageGlobalDashboardCompaniesDrawerMutation(\n    $updateOrganizationsForGlobalDashboardForm: UpdateOrganizationsForGlobalDashboardForm!\n  ) {\n    response: updateOrganizationsForGlobalDashboard(\n      updateOrganizationsForGlobalDashboardForm: $updateOrganizationsForGlobalDashboardForm\n    ) {\n      \n      globalDashboard {\n        id\n      }\n    }\n  }\n':
    types.ManageGlobalDashboardCompaniesDrawerMutationDocument,
  '\n  fragment ManageGlobalDashboardCompaniesDrawerListFragment on Organization {\n    id\n    name\n    company {\n      id\n      primaryOrganization {\n        id\n        name\n      }\n    }\n  }\n':
    types.ManageGlobalDashboardCompaniesDrawerListFragmentFragmentDoc,
  '\n  fragment OrganizationItem on Organization {\n    id\n    name\n    slug\n    exploUserGroupToken\n  }\n':
    types.OrganizationItemFragmentDoc,
  '\n  query SelectOrganizationModal {\n    activeOrganizations {\n      id\n      name\n      slug\n      ...OrganizationItem\n    }\n  }\n':
    types.SelectOrganizationModalDocument,
  '\n  query GlobalDashboardsPage(\n        $sort: String!\n        $status: String\n        $categoryIds: [String!]\n        $tagIds: [String!]\n        $searchQuery: String\n        $onlyDefault: Boolean\n    ) {\n    viewer {\n      id\n    }\n    filteredGlobalDashboards(\n      sort: $sort, \n      status: $status, \n      categoryIds: $categoryIds, \n      tagIds: $tagIds, \n      searchQuery: $searchQuery\n      onlyDefault: $onlyDefault\n    ) {\n      id\n      ...GlobalDashboardCard\n    }\n    publishedGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "PUBLISHED",\n    )\n    draftGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "DRAFT",\n    )\n    archivedGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "ARCHIVED",\n    )\n  }\n':
    types.GlobalDashboardsPageDocument,
  '\n  query CreateGlobalDashboardDrawer {\n    ...GlobalDashboardFields\n  }\n':
    types.CreateGlobalDashboardDrawerDocument,
  '\n  fragment GlobalDashboardCard on GlobalDashboard {\n    id\n    uuid\n    name\n    description\n    isDefault\n    status\n    companiesCount\n    dashboardCategory {\n      id\n      name\n    }\n  }\n':
    types.GlobalDashboardCardFragmentDoc,
  '\n  fragment GlobalDashboardFields on Query {\n    dashboardCategories {\n      id\n      name\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      name\n      ...DashboardTagFormEdit\n    }\n  }\n':
    types.GlobalDashboardFieldsFragmentDoc,
  '\n  query GlobalDashboardFilter {\n    dashboardCategories {\n      id\n      name\n    }\n    dashboardTags {\n      id\n      name\n    }\n  }\n':
    types.GlobalDashboardFilterDocument,
  '\n  query ManageCategoriesDrawer {\n    dashboardCategories {\n        id\n        ...ManageDashboardCategoriesList\n    }\n  }\n':
    types.ManageCategoriesDrawerDocument,
  '\n  fragment ManageDashboardCategoriesList on DashboardCategory {\n    id\n    ...DashboardCategoryFormEdit\n    ...ManageDashboardCategoryDeleteModalCategory\n  }\n':
    types.ManageDashboardCategoriesListFragmentDoc,
  '\n  query ManageDashboardCategoryDeleteModal($dashboardCategoryId: Int!) {\n    dashboardCategory(dashboardCategoryId: $dashboardCategoryId) {\n      id\n      globalDashboardsAndDashboardsCount\n    }\n  }\n':
    types.ManageDashboardCategoryDeleteModalDocument,
  '\n  fragment ManageDashboardCategoryDeleteModalCategory on DashboardCategory {\n    id\n    name\n  }\n':
    types.ManageDashboardCategoryDeleteModalCategoryFragmentDoc,
  '\n  query ManageDashboardTagsDrawer {\n    dashboardTags {\n        id\n        ...DashboardTagFormEdit\n    }\n  }\n':
    types.ManageDashboardTagsDrawerDocument,
  '\n  query UpdateGlobalDashboardDrawer($globalDashboardUuid: String!) {\n    globalDashboard(uuid: $globalDashboardUuid) {\n      id\n      ...GlobalDashboardFormEdit\n    }\n    ...GlobalDashboardFields\n  }\n':
    types.UpdateGlobalDashboardDrawerDocument,
  '\n  query OrganizationSettingsCustomerFlowsFilter($category: String) {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        projectTypesForCategory(category: $category) {\n          id\n          name\n        }\n      }\n    }\n  }\n':
    types.OrganizationSettingsCustomerFlowsFilterDocument,
  '\n  query DashboardsPage {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        settings {\n          id\n          isStorageEnabled\n        }\n      }\n    }\n    organization {\n      id\n      dashboards {\n        id\n        uuid\n        name\n        dashboardCategoryId\n        globalDashboard {\n          id\n          dashboardCategoryId\n        }\n      }\n    }\n    dashboardCategories {\n      id\n      name\n      index\n    }\n  }\n':
    types.DashboardsPageDocument,
  '\n      mutation CreateDashboardFromGlobalDashboardMutation($dashboardFromGlobalDashboardForm: DashboardFromGlobalDashboardForm!) {\n        response: createDashboardFromGlobalDashboard(dashboardFromGlobalDashboardForm: $dashboardFromGlobalDashboardForm) {\n          \n          dashboard {\n            id\n            uuid\n            name\n          }\n        }\n      }\n    ':
    types.CreateDashboardFromGlobalDashboardMutationDocument,
  '\n  \n  query GlobalDashboardsLibraryPageQuery(\n    $sort: String!\n    $status: String\n    $categoryIds: [String!]\n    $tagIds: [String!]\n    $searchQuery: String\n    $onlyDefault: Boolean\n  ) {\n    \n    filteredGlobalDashboards(\n      sort: $sort\n      status: $status\n      categoryIds: $categoryIds\n      tagIds: $tagIds\n      searchQuery: $searchQuery\n      onlyDefault: $onlyDefault\n    ) {\n      id\n      dashboardCategory {\n        id\n        name\n      }\n      ...GlobalDashboardsLibraryPageGlobalDashboardsListItem\n    }\n    viewer {\n      id\n      viewingOrganization {\n        id\n        settings {\n          id\n          isStorageEnabled\n        }\n      }\n    }\n    organization {\n      id\n      dashboards {\n        id\n        uuid\n        globalDashboard {\n          id\n          uuid\n        }\n      }\n    }\n  }\n':
    types.GlobalDashboardsLibraryPageQueryDocument,
  '\n  \n  query GlobalDashboardsLibraryPageCreateDashboardDrawer {\n    \n    ...GlobalDashboardsLibraryPageDashboardFields\n  }\n':
    types.GlobalDashboardsLibraryPageCreateDashboardDrawerDocument,
  '\n  \n  \n  fragment GlobalDashboardsLibraryPageDashboardFields on Query {\n    \n    dashboardCategories {\n      id\n      name\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      name\n      ...DashboardTagFormEdit\n    }\n  }\n':
    types.GlobalDashboardsLibraryPageDashboardFieldsFragmentDoc,
  '\n  \n  query GlobalDashboardsLibraryPageGlobalDashboardViewPanel ($uuid: String!) {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        exploUserGroupToken\n      }\n    }\n    globalDashboard(uuid: $uuid) { \n      id\n      name\n      exploDashboardEmbedId\n      exploVariables\n      ...GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment\n    }\n  }\n':
    types.GlobalDashboardsLibraryPageGlobalDashboardViewPanelDocument,
  '\n  fragment GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment on GlobalDashboard {\n    id\n    name\n    description\n    helpArticleUrl\n    dashboardCategory {\n      id\n      name\n    }\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n  }\n':
    types.GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragmentFragmentDoc,
  '\n  query GlobalDashboardsLibraryPageGlobalDashboardsFilter {\n    \n    dashboardCategories {\n        id\n        name\n    }\n    dashboardTags {\n        id\n        name\n    }\n  }\n':
    types.GlobalDashboardsLibraryPageGlobalDashboardsFilterDocument,
  '\n  fragment GlobalDashboardsLibraryPageGlobalDashboardsListItem on GlobalDashboard {\n    id\n    uuid\n    name\n    description\n    publishedAt\n  }\n':
    types.GlobalDashboardsLibraryPageGlobalDashboardsListItemFragmentDoc,
  '\n  \n  \n  query GlobalDashboardsLibraryPageUpdateDashboardDrawer($uuid: String!) {\n    \n    dashboard(dashboardUuid: $uuid) { \n      id\n      ...DashboardFormEdit\n    }\n    ...GlobalDashboardsLibraryPageDashboardFields\n  }\n':
    types.GlobalDashboardsLibraryPageUpdateDashboardDrawerDocument,
  '\n  query EditDashboardModal {\n    \n    dashboardCategories {\n      id\n      name\n    }\n  }\n':
    types.EditDashboardModalDocument,
  '\n  fragment DashboardsPageEditDashboardModalFragment on Dashboard {\n    id\n    uuid\n    organizationId\n    name\n    description\n    dashboardCategoryId\n    exploVariables\n  }\n':
    types.DashboardsPageEditDashboardModalFragmentFragmentDoc,
  '\n      mutation useDeleteDashboardMutation($dashboardForm: DashboardForm!) {\n        response: deleteDashboard(dashboardForm: $dashboardForm) {\n          \n          dashboard {\n            id\n          }\n        }\n      }\n    ':
    types.UseDeleteDashboardMutationDocument,
  '\n  \n  fragment DashboardsPageRemoveDashboardModalFragment on Dashboard {\n    id\n    ...DashboardFormEdit\n  }\n':
    types.DashboardsPageRemoveDashboardModalFragmentFragmentDoc,
  '\n      mutation useResetDashboardToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {\n        response: resetDashboardToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {\n          \n          dashboard {\n            id\n          }\n        }\n      }\n    ':
    types.UseResetDashboardToGlobalDashboardMutationDocument,
  '\n  \n  fragment DashboardsPageUpdateDashboardVariablesModal on Dashboard {\n    id\n    ...DashboardLinkedToGlobalDashboardFormEdit\n  }\n':
    types.DashboardsPageUpdateDashboardVariablesModalFragmentDoc,
  '\n  \n  \n  \n  \n  \n  query DashboardsPageViewPanel ($uuid: String!) {\n    \n    dashboard(dashboardUuid: $uuid) { \n      id\n      name\n      globalDashboardId\n      exploDashboardEmbedId\n      exploVariables\n      organization {\n        id\n        exploUserGroupToken\n        features {\n          isEnabledOfficeReportsUpdateAndResetView: isEnabled(feature: "OFFICE_REPORTS_UPDATE_AND_RESET_VIEW")\n        }\n      }\n      ...DashboardsPageViewPanelHeaderFragment\n      ...DashboardsPageEditDashboardModalFragment\n      ...DashboardsPageRemoveDashboardModalFragment\n      ...DashboardsPageUpdateDashboardVariablesModal\n      ...Dashboard_isVariablesChanged\n    }\n  }\n':
    types.DashboardsPageViewPanelDocument,
  '\n  \n  fragment DashboardsPageViewPanelHeaderFragment on Dashboard {\n    id\n    name\n    description\n    dashboardCategoryId\n    exploVariables\n    helpArticleUrl\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n    globalDashboard {\n      id\n      name\n      description\n      dashboardCategoryId\n      helpArticleUrl\n      exploVariables\n    }\n    ...Dashboard_isDashboardDifferentFromGlobalDashboard\n  }\n':
    types.DashboardsPageViewPanelHeaderFragmentFragmentDoc,
  '\n    fragment Dashboard_isVariablesChanged on Dashboard {\n      id\n      exploVariables\n    }\n  ':
    types.Dashboard_IsVariablesChangedFragmentDoc,
  '\n    \n    fragment Dashboard_isDashboardDifferentFromGlobalDashboard on Dashboard {\n      id\n      dashboardCategoryId\n      name\n      description\n      exploVariables\n      globalDashboard {\n        dashboardCategoryId\n        name\n        description\n        exploVariables\n      }\n      ...Dashboard_isVariablesChanged\n    }\n  ':
    types.Dashboard_IsDashboardDifferentFromGlobalDashboardFragmentDoc,
  '\n  fragment DashboardCategoryFormEdit on DashboardCategory {\n    id\n    name\n    index\n    isDeleted\n  }\n':
    types.DashboardCategoryFormEditFragmentDoc,
  '\n  mutation useCreateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: createDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n':
    types.UseCreateDashboardCategoryMutationDocument,
  '\n  mutation useDeleteDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: deleteDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n':
    types.UseDeleteDashboardCategoryMutationDocument,
  '\n  mutation useReorderDashboardCategoriesMutation(\n    $reorderDashboardCategoriesForm: ReorderDashboardCategoriesForm!\n  ) {\n    response: reorderDashboardCategories(\n      reorderDashboardCategoriesForm: $reorderDashboardCategoriesForm\n    ) {\n      errors {\n        field\n        message\n      }\n      dashboardCategories {\n        id\n      }\n    }\n  }\n':
    types.UseReorderDashboardCategoriesMutationDocument,
  '\n  mutation useUpdateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: updateDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n':
    types.UseUpdateDashboardCategoryMutationDocument,
  '\n  fragment DashboardTagFormEdit on Tag {\n    id\n    name\n    isDeleted\n  }\n':
    types.DashboardTagFormEditFragmentDoc,
  '\n  mutation useCreateDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: createDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n':
    types.UseCreateDashboardTagMutationDocument,
  '\n  mutation useDeleteDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: deleteDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n':
    types.UseDeleteDashboardTagMutationDocument,
  '\n  mutation useUpdateDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: updateDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n':
    types.UseUpdateDashboardTagMutationDocument,
  '\n  fragment GlobalDashboardFormEdit on GlobalDashboard {\n    id\n    name\n    description\n    helpArticleUrl\n    isDefault\n    exploDashboardEmbedId\n    exploVariables\n    dashboardCategory {\n      id\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      tag {\n        ...DashboardTagFormEdit\n      }\n    }\n  }\n':
    types.GlobalDashboardFormEditFragmentDoc,
  '\n  mutation useCreateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {\n    response: createGlobalDashboard(globalDashboardForm: $globalDashboardForm) {\n      errors {\n        field\n        message\n      }\n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n':
    types.UseCreateGlobalDashboardMutationDocument,
  '\n  mutation useDuplicateGlobalDashboardMutation($duplicateGlobalDashboardForm: DuplicateGlobalDashboardForm!) {\n    response: duplicateGlobalDashboard(duplicateGlobalDashboardForm: $duplicateGlobalDashboardForm) {\n      \n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n':
    types.UseDuplicateGlobalDashboardMutationDocument,
  '\n  mutation useUpdateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {\n    response: updateGlobalDashboard(globalDashboardForm: $globalDashboardForm) {\n      errors {\n        field\n        message\n      }\n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n':
    types.UseUpdateGlobalDashboardMutationDocument,
  '\n  mutation useUpdateGlobalDashboardStatusMutation($updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusForm!) {\n    response: updateGlobalDashboardStatus(updateGlobalDashboardStatusForm: $updateGlobalDashboardStatusForm) {\n      \n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n':
    types.UseUpdateGlobalDashboardStatusMutationDocument,
  '\n  fragment DashboardFormEdit on Dashboard {\n    id\n    organizationId\n    kind\n    category\n    name\n    exploDashboardEmbedId\n    exploVariables\n    index\n    uuid\n    dashboardCategoryId\n    globalDashboardId\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n    description\n    helpArticleUrl\n  }\n':
    types.DashboardFormEditFragmentDoc,
  '\n  fragment DashboardLinkedToGlobalDashboardFormEdit on Dashboard {\n    id\n    organizationId\n    dashboardCategoryId\n    name\n    description\n    exploVariables\n  }\n':
    types.DashboardLinkedToGlobalDashboardFormEditFragmentDoc,
  '\n    \n\n    fragment ReorderReportDashboardsForm_edit on Dashboard {\n      id\n      ...DashboardFormEdit\n    }\n  ':
    types.ReorderReportDashboardsForm_EditFragmentDoc,
  '\n  mutation useCreateDashboardMutation($dashboardForm: DashboardForm!) {\n    response: createDashboard(dashboardForm: $dashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n':
    types.UseCreateDashboardMutationDocument,
  '\n  mutation useCreateReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: createReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n':
    types.UseCreateReportDashboardMutationDocument,
  '\n  mutation useDeleteReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: deleteReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n':
    types.UseDeleteReportDashboardMutationDocument,
  '\n  mutation useReorderReportDashboardsMutation($reorderReportDashboardsForm: ReorderReportDashboardsForm!) {\n    response: reorderReportDashboards(reorderReportDashboardsForm: $reorderReportDashboardsForm) {\n      \n      dashboards {\n        id\n      }\n    }\n  }\n':
    types.UseReorderReportDashboardsMutationDocument,
  '\n  mutation useUpdateDashboardLinkedToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {\n    response: updateDashboardLinkedToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n':
    types.UseUpdateDashboardLinkedToGlobalDashboardMutationDocument,
  '\n  mutation useUpdateDashboardMutation($dashboardForm: DashboardForm!) {\n    response: updateDashboard(dashboardForm: $dashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n':
    types.UseUpdateDashboardMutationDocument,
  '\n  mutation useUpdateReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: updateReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n':
    types.UseUpdateReportDashboardMutationDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GlobalDashboardDetailsPage(\n        $globalDashboardUuid: String!\n    ) {\n    viewer {\n      id\n      viewingOrganization {\n        id\n        exploUserGroupToken\n      }\n    }\n    globalDashboard(\n      uuid: $globalDashboardUuid, \n    ) {\n        id\n        uuid\n        name\n        exploDashboardEmbedId\n        exploVariables\n        status\n        ...GlobalDashboardDetailsPageSettingsPanel\n        ...GlobalDashboardDetailsPageAboutPanel\n    }\n  }\n',
): (typeof documents)['\n  query GlobalDashboardDetailsPage(\n        $globalDashboardUuid: String!\n    ) {\n    viewer {\n      id\n      viewingOrganization {\n        id\n        exploUserGroupToken\n      }\n    }\n    globalDashboard(\n      uuid: $globalDashboardUuid, \n    ) {\n        id\n        uuid\n        name\n        exploDashboardEmbedId\n        exploVariables\n        status\n        ...GlobalDashboardDetailsPageSettingsPanel\n        ...GlobalDashboardDetailsPageAboutPanel\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ExploDashboardPanel($slug: String) {\n    viewer {\n      id\n      viewingOrganization {\n        id\n        slug\n      }\n    }\n    organization(slug: $slug) {\n      id\n      ...OrganizationItem\n    }\n  }\n',
): (typeof documents)['\n  query ExploDashboardPanel($slug: String) {\n    viewer {\n      id\n      viewingOrganization {\n        id\n        slug\n      }\n    }\n    organization(slug: $slug) {\n      id\n      ...OrganizationItem\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GlobalDashboardDetailsPageAboutPanel on GlobalDashboard {\n    id\n    uuid\n    companiesCount\n    publishedAt\n    publishedBy {\n      id\n      fullName\n    }\n    updatedAt\n    updatedBy {\n      id\n      fullName\n    }\n  }\n',
): (typeof documents)['\n  fragment GlobalDashboardDetailsPageAboutPanel on GlobalDashboard {\n    id\n    uuid\n    companiesCount\n    publishedAt\n    publishedBy {\n      id\n      fullName\n    }\n    updatedAt\n    updatedBy {\n      id\n      fullName\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GlobalDashboardDetailsPageSettingsPanel on GlobalDashboard {\n    id\n    name\n    status\n    description\n    isDefault\n    helpArticleUrl\n    isDefault\n    exploVariables\n    exploDashboardEmbedId\n    dashboardCategory {\n      id\n      name\n    }\n    dashboardTags {\n      tag {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment GlobalDashboardDetailsPageSettingsPanel on GlobalDashboard {\n    id\n    name\n    status\n    description\n    isDefault\n    helpArticleUrl\n    isDefault\n    exploVariables\n    exploDashboardEmbedId\n    dashboardCategory {\n      id\n      name\n    }\n    dashboardTags {\n      tag {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  query ManageGlobalDashboardCompaniesDrawer($globalDashboardUuid: String!) {\n    \n    globalDashboard(uuid: $globalDashboardUuid) {\n        id\n        dashboards {\n          id\n          uuid\n          organizationId\n        }\n    }\n    activeOrganizations {\n      id\n      ...ManageGlobalDashboardCompaniesDrawerListFragment\n    }\n  }\n',
): (typeof documents)['\n  \n  query ManageGlobalDashboardCompaniesDrawer($globalDashboardUuid: String!) {\n    \n    globalDashboard(uuid: $globalDashboardUuid) {\n        id\n        dashboards {\n          id\n          uuid\n          organizationId\n        }\n    }\n    activeOrganizations {\n      id\n      ...ManageGlobalDashboardCompaniesDrawerListFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ManageGlobalDashboardCompaniesDrawerMutation(\n    $updateOrganizationsForGlobalDashboardForm: UpdateOrganizationsForGlobalDashboardForm!\n  ) {\n    response: updateOrganizationsForGlobalDashboard(\n      updateOrganizationsForGlobalDashboardForm: $updateOrganizationsForGlobalDashboardForm\n    ) {\n      \n      globalDashboard {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation ManageGlobalDashboardCompaniesDrawerMutation(\n    $updateOrganizationsForGlobalDashboardForm: UpdateOrganizationsForGlobalDashboardForm!\n  ) {\n    response: updateOrganizationsForGlobalDashboard(\n      updateOrganizationsForGlobalDashboardForm: $updateOrganizationsForGlobalDashboardForm\n    ) {\n      \n      globalDashboard {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ManageGlobalDashboardCompaniesDrawerListFragment on Organization {\n    id\n    name\n    company {\n      id\n      primaryOrganization {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ManageGlobalDashboardCompaniesDrawerListFragment on Organization {\n    id\n    name\n    company {\n      id\n      primaryOrganization {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment OrganizationItem on Organization {\n    id\n    name\n    slug\n    exploUserGroupToken\n  }\n',
): (typeof documents)['\n  fragment OrganizationItem on Organization {\n    id\n    name\n    slug\n    exploUserGroupToken\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query SelectOrganizationModal {\n    activeOrganizations {\n      id\n      name\n      slug\n      ...OrganizationItem\n    }\n  }\n',
): (typeof documents)['\n  query SelectOrganizationModal {\n    activeOrganizations {\n      id\n      name\n      slug\n      ...OrganizationItem\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GlobalDashboardsPage(\n        $sort: String!\n        $status: String\n        $categoryIds: [String!]\n        $tagIds: [String!]\n        $searchQuery: String\n        $onlyDefault: Boolean\n    ) {\n    viewer {\n      id\n    }\n    filteredGlobalDashboards(\n      sort: $sort, \n      status: $status, \n      categoryIds: $categoryIds, \n      tagIds: $tagIds, \n      searchQuery: $searchQuery\n      onlyDefault: $onlyDefault\n    ) {\n      id\n      ...GlobalDashboardCard\n    }\n    publishedGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "PUBLISHED",\n    )\n    draftGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "DRAFT",\n    )\n    archivedGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "ARCHIVED",\n    )\n  }\n',
): (typeof documents)['\n  query GlobalDashboardsPage(\n        $sort: String!\n        $status: String\n        $categoryIds: [String!]\n        $tagIds: [String!]\n        $searchQuery: String\n        $onlyDefault: Boolean\n    ) {\n    viewer {\n      id\n    }\n    filteredGlobalDashboards(\n      sort: $sort, \n      status: $status, \n      categoryIds: $categoryIds, \n      tagIds: $tagIds, \n      searchQuery: $searchQuery\n      onlyDefault: $onlyDefault\n    ) {\n      id\n      ...GlobalDashboardCard\n    }\n    publishedGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "PUBLISHED",\n    )\n    draftGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "DRAFT",\n    )\n    archivedGlobalDashboardsCount: filteredGlobalDashboardsCount(\n      status: "ARCHIVED",\n    )\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CreateGlobalDashboardDrawer {\n    ...GlobalDashboardFields\n  }\n',
): (typeof documents)['\n  query CreateGlobalDashboardDrawer {\n    ...GlobalDashboardFields\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GlobalDashboardCard on GlobalDashboard {\n    id\n    uuid\n    name\n    description\n    isDefault\n    status\n    companiesCount\n    dashboardCategory {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment GlobalDashboardCard on GlobalDashboard {\n    id\n    uuid\n    name\n    description\n    isDefault\n    status\n    companiesCount\n    dashboardCategory {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GlobalDashboardFields on Query {\n    dashboardCategories {\n      id\n      name\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      name\n      ...DashboardTagFormEdit\n    }\n  }\n',
): (typeof documents)['\n  fragment GlobalDashboardFields on Query {\n    dashboardCategories {\n      id\n      name\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      name\n      ...DashboardTagFormEdit\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GlobalDashboardFilter {\n    dashboardCategories {\n      id\n      name\n    }\n    dashboardTags {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query GlobalDashboardFilter {\n    dashboardCategories {\n      id\n      name\n    }\n    dashboardTags {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ManageCategoriesDrawer {\n    dashboardCategories {\n        id\n        ...ManageDashboardCategoriesList\n    }\n  }\n',
): (typeof documents)['\n  query ManageCategoriesDrawer {\n    dashboardCategories {\n        id\n        ...ManageDashboardCategoriesList\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ManageDashboardCategoriesList on DashboardCategory {\n    id\n    ...DashboardCategoryFormEdit\n    ...ManageDashboardCategoryDeleteModalCategory\n  }\n',
): (typeof documents)['\n  fragment ManageDashboardCategoriesList on DashboardCategory {\n    id\n    ...DashboardCategoryFormEdit\n    ...ManageDashboardCategoryDeleteModalCategory\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ManageDashboardCategoryDeleteModal($dashboardCategoryId: Int!) {\n    dashboardCategory(dashboardCategoryId: $dashboardCategoryId) {\n      id\n      globalDashboardsAndDashboardsCount\n    }\n  }\n',
): (typeof documents)['\n  query ManageDashboardCategoryDeleteModal($dashboardCategoryId: Int!) {\n    dashboardCategory(dashboardCategoryId: $dashboardCategoryId) {\n      id\n      globalDashboardsAndDashboardsCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ManageDashboardCategoryDeleteModalCategory on DashboardCategory {\n    id\n    name\n  }\n',
): (typeof documents)['\n  fragment ManageDashboardCategoryDeleteModalCategory on DashboardCategory {\n    id\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ManageDashboardTagsDrawer {\n    dashboardTags {\n        id\n        ...DashboardTagFormEdit\n    }\n  }\n',
): (typeof documents)['\n  query ManageDashboardTagsDrawer {\n    dashboardTags {\n        id\n        ...DashboardTagFormEdit\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query UpdateGlobalDashboardDrawer($globalDashboardUuid: String!) {\n    globalDashboard(uuid: $globalDashboardUuid) {\n      id\n      ...GlobalDashboardFormEdit\n    }\n    ...GlobalDashboardFields\n  }\n',
): (typeof documents)['\n  query UpdateGlobalDashboardDrawer($globalDashboardUuid: String!) {\n    globalDashboard(uuid: $globalDashboardUuid) {\n      id\n      ...GlobalDashboardFormEdit\n    }\n    ...GlobalDashboardFields\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query OrganizationSettingsCustomerFlowsFilter($category: String) {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        projectTypesForCategory(category: $category) {\n          id\n          name\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query OrganizationSettingsCustomerFlowsFilter($category: String) {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        projectTypesForCategory(category: $category) {\n          id\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query DashboardsPage {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        settings {\n          id\n          isStorageEnabled\n        }\n      }\n    }\n    organization {\n      id\n      dashboards {\n        id\n        uuid\n        name\n        dashboardCategoryId\n        globalDashboard {\n          id\n          dashboardCategoryId\n        }\n      }\n    }\n    dashboardCategories {\n      id\n      name\n      index\n    }\n  }\n',
): (typeof documents)['\n  query DashboardsPage {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        settings {\n          id\n          isStorageEnabled\n        }\n      }\n    }\n    organization {\n      id\n      dashboards {\n        id\n        uuid\n        name\n        dashboardCategoryId\n        globalDashboard {\n          id\n          dashboardCategoryId\n        }\n      }\n    }\n    dashboardCategories {\n      id\n      name\n      index\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n      mutation CreateDashboardFromGlobalDashboardMutation($dashboardFromGlobalDashboardForm: DashboardFromGlobalDashboardForm!) {\n        response: createDashboardFromGlobalDashboard(dashboardFromGlobalDashboardForm: $dashboardFromGlobalDashboardForm) {\n          \n          dashboard {\n            id\n            uuid\n            name\n          }\n        }\n      }\n    ',
): (typeof documents)['\n      mutation CreateDashboardFromGlobalDashboardMutation($dashboardFromGlobalDashboardForm: DashboardFromGlobalDashboardForm!) {\n        response: createDashboardFromGlobalDashboard(dashboardFromGlobalDashboardForm: $dashboardFromGlobalDashboardForm) {\n          \n          dashboard {\n            id\n            uuid\n            name\n          }\n        }\n      }\n    '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  query GlobalDashboardsLibraryPageQuery(\n    $sort: String!\n    $status: String\n    $categoryIds: [String!]\n    $tagIds: [String!]\n    $searchQuery: String\n    $onlyDefault: Boolean\n  ) {\n    \n    filteredGlobalDashboards(\n      sort: $sort\n      status: $status\n      categoryIds: $categoryIds\n      tagIds: $tagIds\n      searchQuery: $searchQuery\n      onlyDefault: $onlyDefault\n    ) {\n      id\n      dashboardCategory {\n        id\n        name\n      }\n      ...GlobalDashboardsLibraryPageGlobalDashboardsListItem\n    }\n    viewer {\n      id\n      viewingOrganization {\n        id\n        settings {\n          id\n          isStorageEnabled\n        }\n      }\n    }\n    organization {\n      id\n      dashboards {\n        id\n        uuid\n        globalDashboard {\n          id\n          uuid\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  \n  query GlobalDashboardsLibraryPageQuery(\n    $sort: String!\n    $status: String\n    $categoryIds: [String!]\n    $tagIds: [String!]\n    $searchQuery: String\n    $onlyDefault: Boolean\n  ) {\n    \n    filteredGlobalDashboards(\n      sort: $sort\n      status: $status\n      categoryIds: $categoryIds\n      tagIds: $tagIds\n      searchQuery: $searchQuery\n      onlyDefault: $onlyDefault\n    ) {\n      id\n      dashboardCategory {\n        id\n        name\n      }\n      ...GlobalDashboardsLibraryPageGlobalDashboardsListItem\n    }\n    viewer {\n      id\n      viewingOrganization {\n        id\n        settings {\n          id\n          isStorageEnabled\n        }\n      }\n    }\n    organization {\n      id\n      dashboards {\n        id\n        uuid\n        globalDashboard {\n          id\n          uuid\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  query GlobalDashboardsLibraryPageCreateDashboardDrawer {\n    \n    ...GlobalDashboardsLibraryPageDashboardFields\n  }\n',
): (typeof documents)['\n  \n  query GlobalDashboardsLibraryPageCreateDashboardDrawer {\n    \n    ...GlobalDashboardsLibraryPageDashboardFields\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  \n  fragment GlobalDashboardsLibraryPageDashboardFields on Query {\n    \n    dashboardCategories {\n      id\n      name\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      name\n      ...DashboardTagFormEdit\n    }\n  }\n',
): (typeof documents)['\n  \n  \n  fragment GlobalDashboardsLibraryPageDashboardFields on Query {\n    \n    dashboardCategories {\n      id\n      name\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      name\n      ...DashboardTagFormEdit\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  query GlobalDashboardsLibraryPageGlobalDashboardViewPanel ($uuid: String!) {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        exploUserGroupToken\n      }\n    }\n    globalDashboard(uuid: $uuid) { \n      id\n      name\n      exploDashboardEmbedId\n      exploVariables\n      ...GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment\n    }\n  }\n',
): (typeof documents)['\n  \n  query GlobalDashboardsLibraryPageGlobalDashboardViewPanel ($uuid: String!) {\n    \n    viewer {\n      id\n      viewingOrganization {\n        id\n        exploUserGroupToken\n      }\n    }\n    globalDashboard(uuid: $uuid) { \n      id\n      name\n      exploDashboardEmbedId\n      exploVariables\n      ...GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment on GlobalDashboard {\n    id\n    name\n    description\n    helpArticleUrl\n    dashboardCategory {\n      id\n      name\n    }\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment on GlobalDashboard {\n    id\n    name\n    description\n    helpArticleUrl\n    dashboardCategory {\n      id\n      name\n    }\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GlobalDashboardsLibraryPageGlobalDashboardsFilter {\n    \n    dashboardCategories {\n        id\n        name\n    }\n    dashboardTags {\n        id\n        name\n    }\n  }\n',
): (typeof documents)['\n  query GlobalDashboardsLibraryPageGlobalDashboardsFilter {\n    \n    dashboardCategories {\n        id\n        name\n    }\n    dashboardTags {\n        id\n        name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GlobalDashboardsLibraryPageGlobalDashboardsListItem on GlobalDashboard {\n    id\n    uuid\n    name\n    description\n    publishedAt\n  }\n',
): (typeof documents)['\n  fragment GlobalDashboardsLibraryPageGlobalDashboardsListItem on GlobalDashboard {\n    id\n    uuid\n    name\n    description\n    publishedAt\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  \n  query GlobalDashboardsLibraryPageUpdateDashboardDrawer($uuid: String!) {\n    \n    dashboard(dashboardUuid: $uuid) { \n      id\n      ...DashboardFormEdit\n    }\n    ...GlobalDashboardsLibraryPageDashboardFields\n  }\n',
): (typeof documents)['\n  \n  \n  query GlobalDashboardsLibraryPageUpdateDashboardDrawer($uuid: String!) {\n    \n    dashboard(dashboardUuid: $uuid) { \n      id\n      ...DashboardFormEdit\n    }\n    ...GlobalDashboardsLibraryPageDashboardFields\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query EditDashboardModal {\n    \n    dashboardCategories {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query EditDashboardModal {\n    \n    dashboardCategories {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DashboardsPageEditDashboardModalFragment on Dashboard {\n    id\n    uuid\n    organizationId\n    name\n    description\n    dashboardCategoryId\n    exploVariables\n  }\n',
): (typeof documents)['\n  fragment DashboardsPageEditDashboardModalFragment on Dashboard {\n    id\n    uuid\n    organizationId\n    name\n    description\n    dashboardCategoryId\n    exploVariables\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n      mutation useDeleteDashboardMutation($dashboardForm: DashboardForm!) {\n        response: deleteDashboard(dashboardForm: $dashboardForm) {\n          \n          dashboard {\n            id\n          }\n        }\n      }\n    ',
): (typeof documents)['\n      mutation useDeleteDashboardMutation($dashboardForm: DashboardForm!) {\n        response: deleteDashboard(dashboardForm: $dashboardForm) {\n          \n          dashboard {\n            id\n          }\n        }\n      }\n    '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  fragment DashboardsPageRemoveDashboardModalFragment on Dashboard {\n    id\n    ...DashboardFormEdit\n  }\n',
): (typeof documents)['\n  \n  fragment DashboardsPageRemoveDashboardModalFragment on Dashboard {\n    id\n    ...DashboardFormEdit\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n      mutation useResetDashboardToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {\n        response: resetDashboardToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {\n          \n          dashboard {\n            id\n          }\n        }\n      }\n    ',
): (typeof documents)['\n      mutation useResetDashboardToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {\n        response: resetDashboardToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {\n          \n          dashboard {\n            id\n          }\n        }\n      }\n    '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  fragment DashboardsPageUpdateDashboardVariablesModal on Dashboard {\n    id\n    ...DashboardLinkedToGlobalDashboardFormEdit\n  }\n',
): (typeof documents)['\n  \n  fragment DashboardsPageUpdateDashboardVariablesModal on Dashboard {\n    id\n    ...DashboardLinkedToGlobalDashboardFormEdit\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  \n  \n  \n  \n  query DashboardsPageViewPanel ($uuid: String!) {\n    \n    dashboard(dashboardUuid: $uuid) { \n      id\n      name\n      globalDashboardId\n      exploDashboardEmbedId\n      exploVariables\n      organization {\n        id\n        exploUserGroupToken\n        features {\n          isEnabledOfficeReportsUpdateAndResetView: isEnabled(feature: "OFFICE_REPORTS_UPDATE_AND_RESET_VIEW")\n        }\n      }\n      ...DashboardsPageViewPanelHeaderFragment\n      ...DashboardsPageEditDashboardModalFragment\n      ...DashboardsPageRemoveDashboardModalFragment\n      ...DashboardsPageUpdateDashboardVariablesModal\n      ...Dashboard_isVariablesChanged\n    }\n  }\n',
): (typeof documents)['\n  \n  \n  \n  \n  \n  query DashboardsPageViewPanel ($uuid: String!) {\n    \n    dashboard(dashboardUuid: $uuid) { \n      id\n      name\n      globalDashboardId\n      exploDashboardEmbedId\n      exploVariables\n      organization {\n        id\n        exploUserGroupToken\n        features {\n          isEnabledOfficeReportsUpdateAndResetView: isEnabled(feature: "OFFICE_REPORTS_UPDATE_AND_RESET_VIEW")\n        }\n      }\n      ...DashboardsPageViewPanelHeaderFragment\n      ...DashboardsPageEditDashboardModalFragment\n      ...DashboardsPageRemoveDashboardModalFragment\n      ...DashboardsPageUpdateDashboardVariablesModal\n      ...Dashboard_isVariablesChanged\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  \n  fragment DashboardsPageViewPanelHeaderFragment on Dashboard {\n    id\n    name\n    description\n    dashboardCategoryId\n    exploVariables\n    helpArticleUrl\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n    globalDashboard {\n      id\n      name\n      description\n      dashboardCategoryId\n      helpArticleUrl\n      exploVariables\n    }\n    ...Dashboard_isDashboardDifferentFromGlobalDashboard\n  }\n',
): (typeof documents)['\n  \n  fragment DashboardsPageViewPanelHeaderFragment on Dashboard {\n    id\n    name\n    description\n    dashboardCategoryId\n    exploVariables\n    helpArticleUrl\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n    globalDashboard {\n      id\n      name\n      description\n      dashboardCategoryId\n      helpArticleUrl\n      exploVariables\n    }\n    ...Dashboard_isDashboardDifferentFromGlobalDashboard\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment Dashboard_isVariablesChanged on Dashboard {\n      id\n      exploVariables\n    }\n  ',
): (typeof documents)['\n    fragment Dashboard_isVariablesChanged on Dashboard {\n      id\n      exploVariables\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    \n    fragment Dashboard_isDashboardDifferentFromGlobalDashboard on Dashboard {\n      id\n      dashboardCategoryId\n      name\n      description\n      exploVariables\n      globalDashboard {\n        dashboardCategoryId\n        name\n        description\n        exploVariables\n      }\n      ...Dashboard_isVariablesChanged\n    }\n  ',
): (typeof documents)['\n    \n    fragment Dashboard_isDashboardDifferentFromGlobalDashboard on Dashboard {\n      id\n      dashboardCategoryId\n      name\n      description\n      exploVariables\n      globalDashboard {\n        dashboardCategoryId\n        name\n        description\n        exploVariables\n      }\n      ...Dashboard_isVariablesChanged\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DashboardCategoryFormEdit on DashboardCategory {\n    id\n    name\n    index\n    isDeleted\n  }\n',
): (typeof documents)['\n  fragment DashboardCategoryFormEdit on DashboardCategory {\n    id\n    name\n    index\n    isDeleted\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useCreateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: createDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useCreateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: createDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useDeleteDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: deleteDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useDeleteDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: deleteDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useReorderDashboardCategoriesMutation(\n    $reorderDashboardCategoriesForm: ReorderDashboardCategoriesForm!\n  ) {\n    response: reorderDashboardCategories(\n      reorderDashboardCategoriesForm: $reorderDashboardCategoriesForm\n    ) {\n      errors {\n        field\n        message\n      }\n      dashboardCategories {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useReorderDashboardCategoriesMutation(\n    $reorderDashboardCategoriesForm: ReorderDashboardCategoriesForm!\n  ) {\n    response: reorderDashboardCategories(\n      reorderDashboardCategoriesForm: $reorderDashboardCategoriesForm\n    ) {\n      errors {\n        field\n        message\n      }\n      dashboardCategories {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useUpdateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: updateDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useUpdateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {\n    response: updateDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {\n      errors {\n        field\n        message\n      }\n      dashboardCategory {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DashboardTagFormEdit on Tag {\n    id\n    name\n    isDeleted\n  }\n',
): (typeof documents)['\n  fragment DashboardTagFormEdit on Tag {\n    id\n    name\n    isDeleted\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useCreateDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: createDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useCreateDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: createDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useDeleteDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: deleteDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useDeleteDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: deleteDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useUpdateDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: updateDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useUpdateDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {\n    response: updateDashboardTag(dashboardTagForm: $dashboardTagForm) {\n      errors {\n        field\n        message\n      }\n      dashboardTag {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GlobalDashboardFormEdit on GlobalDashboard {\n    id\n    name\n    description\n    helpArticleUrl\n    isDefault\n    exploDashboardEmbedId\n    exploVariables\n    dashboardCategory {\n      id\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      tag {\n        ...DashboardTagFormEdit\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment GlobalDashboardFormEdit on GlobalDashboard {\n    id\n    name\n    description\n    helpArticleUrl\n    isDefault\n    exploDashboardEmbedId\n    exploVariables\n    dashboardCategory {\n      id\n      ...DashboardCategoryFormEdit\n    }\n    dashboardTags {\n      id\n      tag {\n        ...DashboardTagFormEdit\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useCreateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {\n    response: createGlobalDashboard(globalDashboardForm: $globalDashboardForm) {\n      errors {\n        field\n        message\n      }\n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useCreateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {\n    response: createGlobalDashboard(globalDashboardForm: $globalDashboardForm) {\n      errors {\n        field\n        message\n      }\n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useDuplicateGlobalDashboardMutation($duplicateGlobalDashboardForm: DuplicateGlobalDashboardForm!) {\n    response: duplicateGlobalDashboard(duplicateGlobalDashboardForm: $duplicateGlobalDashboardForm) {\n      \n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useDuplicateGlobalDashboardMutation($duplicateGlobalDashboardForm: DuplicateGlobalDashboardForm!) {\n    response: duplicateGlobalDashboard(duplicateGlobalDashboardForm: $duplicateGlobalDashboardForm) {\n      \n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useUpdateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {\n    response: updateGlobalDashboard(globalDashboardForm: $globalDashboardForm) {\n      errors {\n        field\n        message\n      }\n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useUpdateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {\n    response: updateGlobalDashboard(globalDashboardForm: $globalDashboardForm) {\n      errors {\n        field\n        message\n      }\n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useUpdateGlobalDashboardStatusMutation($updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusForm!) {\n    response: updateGlobalDashboardStatus(updateGlobalDashboardStatusForm: $updateGlobalDashboardStatusForm) {\n      \n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useUpdateGlobalDashboardStatusMutation($updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusForm!) {\n    response: updateGlobalDashboardStatus(updateGlobalDashboardStatusForm: $updateGlobalDashboardStatusForm) {\n      \n      globalDashboard {\n        id\n        uuid\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DashboardFormEdit on Dashboard {\n    id\n    organizationId\n    kind\n    category\n    name\n    exploDashboardEmbedId\n    exploVariables\n    index\n    uuid\n    dashboardCategoryId\n    globalDashboardId\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n    description\n    helpArticleUrl\n  }\n',
): (typeof documents)['\n  fragment DashboardFormEdit on Dashboard {\n    id\n    organizationId\n    kind\n    category\n    name\n    exploDashboardEmbedId\n    exploVariables\n    index\n    uuid\n    dashboardCategoryId\n    globalDashboardId\n    dashboardTags {\n      id\n      tag {\n        id\n        name\n      }\n    }\n    description\n    helpArticleUrl\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DashboardLinkedToGlobalDashboardFormEdit on Dashboard {\n    id\n    organizationId\n    dashboardCategoryId\n    name\n    description\n    exploVariables\n  }\n',
): (typeof documents)['\n  fragment DashboardLinkedToGlobalDashboardFormEdit on Dashboard {\n    id\n    organizationId\n    dashboardCategoryId\n    name\n    description\n    exploVariables\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    \n\n    fragment ReorderReportDashboardsForm_edit on Dashboard {\n      id\n      ...DashboardFormEdit\n    }\n  ',
): (typeof documents)['\n    \n\n    fragment ReorderReportDashboardsForm_edit on Dashboard {\n      id\n      ...DashboardFormEdit\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useCreateDashboardMutation($dashboardForm: DashboardForm!) {\n    response: createDashboard(dashboardForm: $dashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useCreateDashboardMutation($dashboardForm: DashboardForm!) {\n    response: createDashboard(dashboardForm: $dashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useCreateReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: createReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useCreateReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: createReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useDeleteReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: deleteReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useDeleteReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: deleteReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useReorderReportDashboardsMutation($reorderReportDashboardsForm: ReorderReportDashboardsForm!) {\n    response: reorderReportDashboards(reorderReportDashboardsForm: $reorderReportDashboardsForm) {\n      \n      dashboards {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useReorderReportDashboardsMutation($reorderReportDashboardsForm: ReorderReportDashboardsForm!) {\n    response: reorderReportDashboards(reorderReportDashboardsForm: $reorderReportDashboardsForm) {\n      \n      dashboards {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useUpdateDashboardLinkedToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {\n    response: updateDashboardLinkedToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useUpdateDashboardLinkedToGlobalDashboardMutation($dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm!) {\n    response: updateDashboardLinkedToGlobalDashboard(dashboardLinkedToGlobalDashboardForm: $dashboardLinkedToGlobalDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useUpdateDashboardMutation($dashboardForm: DashboardForm!) {\n    response: updateDashboard(dashboardForm: $dashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useUpdateDashboardMutation($dashboardForm: DashboardForm!) {\n    response: updateDashboard(dashboardForm: $dashboardForm) {\n      \n      dashboard {\n        id\n        name\n        uuid\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation useUpdateReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: updateReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation useUpdateReportDashboardMutation($reportDashboardForm: DashboardForm!) {\n    response: updateReportDashboard(reportDashboardForm: $reportDashboardForm) {\n      \n      dashboard {\n        id\n      }\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
