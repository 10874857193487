// Libraries
import React, {PropsWithChildren} from 'react';

// Supermove
import {ApolloTypedClientType, ApolloTypedProvider} from '@supermove/graphql';

const AppTyped = ({
  client,
  children,
}: PropsWithChildren<{client: ApolloTypedClientType; children: React.ReactNode}>) => (
  <ApolloTypedProvider client={client}>{children}</ApolloTypedProvider>
);

export default AppTyped;
