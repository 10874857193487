module.exports = [{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-react-x/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-amplitude/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"99205f52588f0769aacf342c410a9ea0"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140401595-6"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"81ee860c5e6263e5f67e0446c7833759"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/apps/admin/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
